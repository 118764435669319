<template>
        <div>
            <loading-component></loading-component>
            <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
            <van-cell-group inset >
                <van-form>
                    <van-field
                        v-model="driver.name"
                        name="name"
                        label="姓名"
                        placeholder="请输入真实姓名"
                        :rules="[{ required: true, message: '请填写真实姓名' }]"
                    />
                    <number-keyboard-component
                        name="icard"
                        label="身份证号"
                        extra_key="X"
                        right_icon=""
                        placeholder="请输入身份证号"
                        :fvalue="driver.icard"
                        @on_input="confirm_icard"
                    ></number-keyboard-component>
                    <van-field
                        v-model="driver.phone"
                        name="phone"
                        label="手机号"
                        placeholder="请输入手机号"
                        :rules="[{ required: true, message: '请填写手机号' }]"
                    />
                    <van-field
                        v-model="driver.yzm"
                        name="yzm"
                        label="验证码"
                        placeholder="请输入验证码"
                    >
                        <template #right-icon>
                            <van-button v-if="!sendPhoneTime" block type="info" size="small" @click="get_yzm">获取验证码</van-button>
                            <van-button v-else block type="info" size="small" disabled>重新获取({{ sendPhoneTime }})</van-button>
                        </template>
                    </van-field>
                </van-form>
                <div class="sub_button">
                    <van-button round block type="primary" @click="sumbitEdit">提 交</van-button>
                </div>
                <div class="sub_button">
                    <van-button round block type="default" @click="back_details">返 回</van-button>
                </div>
            </van-cell-group>
        </div>

</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import NumberKeyboardComponent from '@/components/common/NumberKeyboardComponent.vue'

import { driver_information_request,lf_edit_yzm_request,driver_edit_request } from '@/network/user'

export default {
    name:'UserEditComponent',
    data(){
        return {
            driver: {
                name: '',
                icard: '',
                phone: '',
                yzm: ''
            },
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
                fixed: false,   //固定
                placeholder: false  //等高占位
            },
            my_nav_bar_name: '修改个人资料',
            sendPhoneTime: 0
        }
    },
    computed:{},
    methods:{
        get_my_information() {
            this.$store.commit('true_loading')
            driver_information_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.driver = {
                            id: s.result.id,
                            name: s.result.name,
                            icard: s.result.icard,
                            phone: s.result.phone,
                            yzm: ''
                        }
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_yzm() {
            this.$store.commit('true_loading')
            this.sendPhoneTime = 60
            lf_edit_yzm_request({phone: this.driver.phone})
                    .then((s) => {
                        if (s.status === 0) {
                            this.$toast.success('验证码已发送')
                            const changeSendPhoneTime = setInterval(() => {
                                if (this.sendPhoneTime) {
                                    this.sendPhoneTime--
                                } else {
                                    clearInterval(changeSendPhoneTime)
                                }
                            }, 1000)
                        } else {
                            this.$toast.fail(s.msg)
                            this.sendPhoneTime = 0
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg)
                        this.sendPhoneTime = 0
                    })
                    .finally(() => this.$store.commit('false_loading'))
        },
        sumbitEdit() {
            this.$store.commit('true_loading')
            driver_edit_request(this.driver)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$toast.success('修改成功');
                            this.$router.replace('/UserInformation')
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => this.$store.commit('false_loading'))
        },
        back_details() {
            this.$router.go(-1);//返回上一层
        },
        confirm_icard(e) {
            this.driver.icard = e
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_my_information()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        NavBarComponent,
        LoadingComponent,
        NumberKeyboardComponent
    },
    watch:{}
}
</script>

<style>
.information_portrait {
    margin-top:0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
}
.information_portrait>img {
    width: 3rem;
    height: 3rem;
    border-radius:50%;
    object-fit: unset;
}
.sub_button {
    margin: 0.5rem;
}
</style>